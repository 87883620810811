import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import apolloClient from './apollo';
import { AuthenticationProvider } from './authentications/AuthenticationContext';
import Layout from './layouts/Layout';

const App = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AuthenticationProvider>
        <Layout />
      </AuthenticationProvider>
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
