import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Typography,
  List,
} from 'antd';

import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const ErrorList = (props) => {
  const { messages } = props;
  const { t } = useTranslation('translations');

  return (
    <>
      <Row align="middle">
        <Col>
          <Title style={{ textAlign: 'center' }} level={4} type="danger">{t('errors.title')}</Title>
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <List
            dataSource={messages}
            size="small"
            renderItem={(message) => (
              <List.Item>
                <Text type="warning">{message}</Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

const propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ErrorList.propTypes = propTypes;

export default ErrorList;
