export default {
  translations: {
    errors: {
      title: 'Qualcosa è andato storto',
    },
    login: {
      loginWithGoogle: 'Accedi con Google',
    },
    devices: {
      eidosDeviceModelName: 'Eidos',
      serialNumber: 'Numero seriale',
      firmwareVersion: 'Versione firmware',
      productionYear: 'Anno produzione',
    },
    parameters: {
      current: 'Corrente',
      power: 'Potenza',
      voltage: 'Voltaggio',
      maxPressure: 'Pressione massima',
      minPressure: 'Pressione minima',
      pressure: 'Pressione',
      temperature: 'Temperatura',
    },
  },
};
