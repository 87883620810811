import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';

import ReactECharts from 'echarts-for-react';

import {
  Row,
  Col,
  Typography,
  Divider,
} from 'antd';

import Loader from '../generics/Loader';
import ErrorList from '../generics/ErrorList';

import { EIDOS_DEVICE_MEASURES_QUERY } from './queries';
import DatePicker from '../generics/DatePicker';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const EidosDevicePage = () => {
  const [fromTo, setFromTo] = React.useState([subMonths(new Date(), 1), new Date()]);
  const params = useParams();
  const { t } = useTranslation('translations');

  const { id } = params;
  const { loading, error, data } = useQuery(EIDOS_DEVICE_MEASURES_QUERY, {
    variables: {
      id,
      from: fromTo[0],
      to: fromTo[1],
    },
  });

  const device = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      return viewer.device;
    }
    return null;
  }, [loading, error, data]);

  const chartOptions = React.useMemo(() => {
    if (device) {
      const { measures } = device;
      const parameters = [...new Set(measures.map(((m) => m.parameter)))];

      const chartMeasures = measures.map((measure) => ({
        timestamp: measure.timestamp,
        [measure.parameter]: measure.value,
        value: measure.value,
      }));
      const dimensions = ['timestamp', ...parameters];

      const series = parameters.map((parameter) => ({
        type: 'line',
        sampling: 'lttb',
        name: t(`parameters.${parameter}`),
        connectNulls: true,
        encode: {
          x: 'timestamp',
          y: parameter,
        },
      }));

      return {
        legend: {},
        tooltip: {},
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        dataset: {
          dimensions,
          source: chartMeasures,
        },
        xAxis: { type: 'time' },
        yAxis: { },
        series,
      };
    }
    return null;
  }, [device]);

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <ErrorList messages={[JSON.stringify(error)]} />
    );
  }

  return (
    <Row>
      <Col xs={24}>
        <Row>
          <Title>{device.serialNumber}</Title>
        </Row>
        <Row>
          <Col xs={24}>
            <RangePicker style={{ width: '100%' }} value={fromTo} onChange={(v) => setFromTo(v)} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={24}>
            <ReactECharts
              style={{ width: '100%', height: 500 }}
              option={chartOptions}
              notMerge
              lazyUpdate
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EidosDevicePage;
