import { gql } from '@apollo/client';

export const EIDOS_DEVICE_LIST_FRAGMENT = gql`
  fragment EidpsDeviceListFragment on EidosDevice {
    firmwareVersion
    productionYear
  }
`;

export const EIDOS_DEVICE_MEASURE_FRAGMENT = gql`
  fragment EidosDeviceMeasureFragment on EidosDevice {
    firmwareVersion
    productionYear
    measures(from: $from, to: $to) {
      parameter
      value
      timestamp
    }
  }
`;

export const DEVICES_QUERY = gql`
  query DevicesQuery {
    viewer {
      devices(first: 50) {
        edges {
          node {
            ...on Node {
              id
            }
            serialNumber
            ...on EidosDevice {
              ...EidpsDeviceListFragment
            }
          }
        }
      }
    }
  }
  ${EIDOS_DEVICE_LIST_FRAGMENT}
`;

export const EIDOS_DEVICE_MEASURES_QUERY = gql`
  query EidosDeviceMeasuresQuery($id: ID!, $from: Date!, $to: Date!) {
    viewer {
      device(id: $id) {
        ...on Node {
          id
        }
        serialNumber
        ...on EidosDevice {
          ...EidosDeviceMeasureFragment
        }
      }
    }
  }
  ${EIDOS_DEVICE_MEASURE_FRAGMENT}
`;
