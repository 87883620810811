import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAUxOLgEeYkepJ9ehShOoDjtO-O28HHU5s',
  authDomain: 'eidos-cloud-ac6d5.firebaseapp.com',
  projectId: 'eidos-cloud-ac6d5',
  storageBucket: 'eidos-cloud-ac6d5.appspot.com',
  messagingSenderId: '250174793839',
  appId: '1:250174793839:web:89133cdb0ae4992acec59a',
};

firebase.initializeApp(firebaseConfig);
