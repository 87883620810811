import React from 'react';
import {
  Layout,
  Avatar,
  Typography,
  Dropdown,
  Menu,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Routes, Route, Outlet } from 'react-router-dom';

import DeviceListPage from '../devices/DeviceListPage';
import EidosDevicePage from '../devices/EidosDevicePage';

import logo from '../assets/eidos-logo.png';
import { AuthenticationContext } from '../authentications/AuthenticationContext';

const { Text } = Typography;

const {
  Header,
  Footer,
  Content,
} = Layout;

const AuthenticatedLayout = () => {
  const { currentUser, logout } = React.useContext(AuthenticationContext);

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>
        <Text>Logout</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header>
        <img src={logo} alt="logo" height="40" />
        <div style={{ float: 'right' }}>
          <Dropdown overlay={menu}>
            <div>
              <Avatar icon={<UserOutlined />} />
              <Text style={{ color: 'white', marginLeft: 5 }}>{currentUser.claims.name}</Text>
            </div>
          </Dropdown>
        </div>
      </Header>
      <Content
        className="layout-content"
      >
        <div className="layout-content-inner">
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="/" element={<DeviceListPage />} />
              <Route path="eidos/:id" element={<EidosDevicePage />} />
            </Route>
          </Routes>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Tekneidos ©2021</Footer>
    </Layout>
  );
};

export default AuthenticatedLayout;
