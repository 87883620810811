import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
} from 'antd';

import logo from '../assets/eidos-logo.png';
import { AuthenticationContext } from './AuthenticationContext';

const { Text } = Typography;

const LoginPage = () => {
  const {
    loading,
    error,
    login,
  } = React.useContext(AuthenticationContext);
  const { t } = useTranslation('translations');

  return (
    <Row align="middle" justify="center" style={{ height: '100%' }}>
      <Col xs={8}>
        <Row justify="center">
          <Col>
            <img src={logo} alt="logo" height="100" />
          </Col>
        </Row>
        <Divider />
        <Row justify="center">
          <Col>
            <Button
              loading={loading}
              onClick={login('google')}
              type="primary"
              icon={<GoogleOutlined />}
            >
              {t('login.loginWithGoogle')}
            </Button>
          </Col>
        </Row>
        {error && (
          <Row justify="center">
            <Col>
              <Text type="danger">{`${error.message} (${error.code})`}</Text>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default LoginPage;
