import React from 'react';
import {
  Row,
  Col,
  Spin,
} from 'antd';

const Loader = () => (
  <Row align="middle" justify="center">
    <Col>
      <Spin size="large" />
    </Col>
  </Row>
);

export default Loader;
