import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LoginPage from '../authentications/LoginPage';

const LoginLayout = () => (
  <Routes>
    <Route path="*" element={<LoginPage />} />
  </Routes>
);

export default LoginLayout;
