import React from 'react';
import firebase from 'firebase';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = (props) => {
  const { children } = props;

  const [loading, setLoading] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [error, setError] = React.useState(null);

  const apolloClient = useApolloClient();

  React.useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(async (user) => {
      setLoading(true);
      if (user) {
        setCurrentUser(await firebase.auth().currentUser.getIdTokenResult(true));
      }
      else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
    return subscriber;
  }, []);

  const googleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    try {
      const result = await firebase.auth().signInWithPopup(provider);
    }
    catch (e) {
      setError(e);
    }
  };

  const login = React.useCallback((provider) => async () => {
    setError(null);
    setLoading(true);
    try {
      if (provider === 'google') {
        await googleSignIn();
      }
    }
    catch (e) {
      setError(e);
    }
    setLoading(false);
  }, []);

  const logout = React.useCallback(async () => {
    await apolloClient.resetStore();
    await firebase.auth().signOut();
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        loading,
        error,
        currentUser,
        login,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const { Consumer: AuthenticationConsumer } = AuthenticationContext;
