import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import firebase from 'firebase';
import config from './config';

const httpLink = createHttpLink({
  uri: config.graphQLHttpUrl,
});

const authLink = setContext(async (_, { headers }) => {
  let tokenResult = await firebase.auth().currentUser.getIdTokenResult(false);
  const expiration = new Date(tokenResult.expirationTime);
  if (expiration.valueOf() < (new Date().valueOf())) {
    tokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
  }
  const { token } = tokenResult;
  const result = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
  return result;
});

const errorLink = onError(async (error) => {
  const { graphQLErrors, networkError } = error;
  if (networkError && networkError.statusCode === 401) {
    await firebase.auth().signOut();
  }
  else if (graphQLErrors) {
    // eslint-disable-next-line no-console
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`));
  }
});

const httpCompositeLink = ApolloLink.from([
  errorLink,
  authLink,
  httpLink,
]);

const apolloClient = new ApolloClient({
  link: httpCompositeLink,
  cache: new InMemoryCache({
    possibleTypes: {
      Device: ['EidosDevice'],
      Node: ['EidosDevice'],
    },
  }),
});

export default apolloClient;
