import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Card,
  List,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Text } = Typography;
const EidosDeviceCard = (props) => {
  const { device, onClick } = props;
  const { t } = useTranslation('translations');

  const deviceDatas = React.useMemo(() => {
    const result = [
      {
        label: t('devices.serialNumber'),
        content: device.serialNumber,
      },
      {
        label: t('devices.productionYear'),
        content: device.productionYear,
      },
      {
        label: t('devices.firmwareVersion'),
        content: device.firmwareVersion,
      },
    ];
    return result;
  }, [device, t]);

  const handleGoClick = React.useCallback(() => {
    onClick(device);
  }, [device]);

  const renderDeviceDataItem = (deviceData) => (
    <List.Item>
      <Text>{`${deviceData.label}:`}</Text>
      <Text>{deviceData.content}</Text>
    </List.Item>
  );

  return (
    <Card
      actions={[
        onClick ? <ArrowRightOutlined key="go" onClick={handleGoClick} /> : null,
      ]}
    >
      <Card.Meta
        title={t('devices.eidosDeviceModelName')}
        description={(
          <List
            size="small"
            dataSource={deviceDatas}
            renderItem={renderDeviceDataItem}
          />
        )}
      />
    </Card>
  );
};

const propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    firmwareVersion: PropTypes.string.isRequired,
    productionYear: PropTypes.number.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: null,
};

EidosDeviceCard.propTypes = propTypes;
EidosDeviceCard.defaultProps = defaultProps;

export default EidosDeviceCard;
