import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {
  List,
} from 'antd';

import { DEVICES_QUERY } from './queries';
import Loader from '../generics/Loader';
import ErrorList from '../generics/ErrorList';

import EidosDeviceCard from './components/EidosDeviceCard';

const DeviceListPage = () => {
  const { loading, error, data } = useQuery(DEVICES_QUERY);
  const navigate = useNavigate();
  const devices = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { devices: deviceConnection } = viewer;
      return deviceConnection.edges.map((d) => d.node);
    }
    return null;
  }, [loading, error, data]);

  const handleDeviceClick = React.useCallback((device) => {
    navigate(`/eidos/${device.id}`);
  }, []);

  const renderDevice = (device) => (
    <EidosDeviceCard device={device} onClick={handleDeviceClick} />
  );

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <ErrorList messages={[JSON.stringify(error)]} />
    );
  }

  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
      }}
      dataSource={devices}
      renderItem={renderDevice}
    />
  );
};

export default DeviceListPage;
