import React from 'react';
import { AuthenticationContext } from '../authentications/AuthenticationContext';
import AuthenticatedLayout from './AuthenticatedLayout';
import LoginLayout from './LoginLayout';

const Layout = () => {
  const { currentUser } = React.useContext(AuthenticationContext);

  console.log(currentUser);
  if (!currentUser) {
    return <LoginLayout />;
  }

  return <AuthenticatedLayout />;
};

export default Layout;
